*, *::before, *::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
}

.l5 {
  position:absolute;
  width: 2800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.l4 {
  position:absolute;
  width: 2800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.l3 {
  position:absolute;
  width: 2800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.l2 {
  position:absolute;
  width: 2800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.l1 {
  position:absolute;
  width: 2800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.parallax {
  pointer-events: none;
  transition: transform 0.45s cubic-bezier(0.02, 0.49, 0.32, .99); 
}

.perspective-container {
  perspective: 1000px; /* Adjust as needed */
}



@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @keyframes shimmer {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -200% 0;
    }
  }

  .animate-shimmer {
    animation: shimmer 2s linear infinite;
  }
}


.custom-shape-divider-bottom-1722819675 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 1000;
}

.custom-shape-divider-bottom-1722819675 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 84px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1722819675 .shape-fill {
  fill: #FFFFFF;
}

.button-container {
  position: relative;
  overflow: hidden;
}

.button-text,
.button-icon {
  transition: transform 0.3s ease-in-out;
}

.button-text {
  position: relative;
  z-index: 1;
}

.button-icon {
  position: absolute;
  top: 50%;
  right: 120%; /* Start from off-screen right */
  transform: translate(0, -50%);
}

.button-container:hover .button-text {
  transform: translateX(150%);
}

.button-container:hover .button-icon {
  transform: translate(720%, -50%);
}

.button-container:not(:hover) .button-text {
  transform: translateX(0);
}

.button-container:not(:hover) .button-icon {
  transform: translate(100%, -50%);
}

.custom-shape-divider-bottom-1722902549 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1722902549 svg {
  position: relative;
  display: block;
  width: calc(127% + 1.3px);
  height: 35px;
}

.custom-shape-divider-bottom-1722902549 .shape-fill {
  fill: #FFFFFF;
}

.aspect-ratio-hero {
  position: relative;
  width: 100%;
  padding-top: calc(850 / 1280 * 100%); /* Aspect Ratio Padding */
}